<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate"> Create New Scheduler </ng-container>
        <ng-template #elseTemplate> Edit Scheduler '{{ schedulerModel.jobName }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading" class="mx-empty-result">
    <div class="mx-data-loading"></div>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="schedulerFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="schedulerFormGroup"
                formControlNameChild="jobName"
                label="Name"
                placeholder="Enter Name"
                [maxLength]="100"
                errorMessage="Name is required or Invalid Input"
                [autoFocusChild]="true"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="select"
                [formGroupChild]="schedulerFormGroup"
                formControlNameChild="schedulerType"
                label="Scheduler Type"
                placeholder="Select scheduler type"
                [compareWithChild]="compareObjects"
                [list]="schedulerTypeOptions"
                [required]="true"
                [disabledChild]="dialogType === 'update'"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="schedulerFormGroup"
                formControlNameChild="cronExpression"
                label="Cron Expression"
                placeholder="Enter Cron Expression"
                [maxLength]="100"
                [required]="true"
            ></mx-input-field>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button
        type="button"
        mat-raised-button
        class="m-1"
        [disabled]="isSaving || schedulerFormGroup.invalid"
        (click)="save()"
        color="accent"
    >
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>
