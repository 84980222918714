import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SchedulerModel } from 'src/app/model/scheduler.model';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { GeneralService } from 'src/app/services/general.service';
import cronstrue from 'cronstrue';
import { ISchedulerTypeOptions, SchedulerService } from 'src/app/services/scheduler.service';

@Component({
    selector: 'app-aggregation-schedule-new-or-edit',
    templateUrl: './scheduler-new-or-edit.component.html',
})
export class SchedulerNewOrEditComponent implements OnInit {
    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    schedulerFormGroup: FormGroup;
    institutions: InstitutionModel[] = [];

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;

    schedulerTypeOptions: ISchedulerTypeOptions[] = SchedulerService.SCHEDULER_TYPE_OPTIONS

    constructor(
        @Inject(MAT_DIALOG_DATA) public schedulerModel: SchedulerModel,
        public dialogRef: MatDialogRef<SchedulerNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private generalService: GeneralService,
        private schedulerService: SchedulerService
    ) {}

    ngOnInit(): void {
        if (this.schedulerModel) {
            this.dialogType = 'update';
            const schedulerType = this.schedulerTypeOptions.find((scheduler: ISchedulerTypeOptions) => scheduler.destination === this.schedulerModel?.data?.destination)
            this.schedulerFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.schedulerModel.id),
                jobName: this.formBuilder.control({ value: this.schedulerModel.jobName, disabled: true }, [
                    Validators.required,
                ]),
                schedulerType: this.formBuilder.control(schedulerType, [Validators.required]),
                cronExpression: this.formBuilder.control(this.schedulerModel.cronExpression, [
                    Validators.required,
                ]),
            });
        } else {
            this.dialogType = 'create';
            this.schedulerFormGroup = this.formBuilder.group({
                jobName: this.formBuilder.control(null, [Validators.required]),
                schedulerType: this.formBuilder.control(null, [Validators.required]),
                cronExpression: this.formBuilder.control(null, [Validators.required]),
            });
        }
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.name === object2.name;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        let scheduleModel: SchedulerModel = this.schedulerFormGroup.getRawValue();
        let cronDescription = '';
        try {
            cronDescription = cronstrue.toString(scheduleModel.cronExpression, { verbose: true });
        } catch (error) {
            this.generalService.snackbarMessage('Invalid Cron Expression', 'Close');
            this.isSaving = false;
            this.deactiveLoading();
        }
        const data = this.schedulerTypeOptions.find((scheduler: ISchedulerTypeOptions) => scheduler.name === scheduleModel.schedulerType?.name)
        scheduleModel.jobGroup = data.jobGroup;
        scheduleModel.data = {
            destination: data.destination,
        };
        scheduleModel.schedulerType = 'CRON';
        if (this.dialogType === 'create') {
            const modalRef = this.matDialog.open(DialogSimpleComponent, {
                data: {
                    title: 'Confirm Add Scheduler',
                    message: `Are you sure want to create ${this.schedulerFormGroup
                        .get('schedulerType')
                        .value.name.toLowerCase()} schedule \"${cronstrue.toString(
                        this.schedulerFormGroup.get('cronExpression').value,
                        { verbose: true }
                    )}\"?`,
                    buttonPositive: 'Yes',
                    buttonPositiveColor: 'warn',
                    buttonNegative: 'No',
                },
                panelClass: 'mat-dialog-sm',
                id: 'create-scheduler',
            });

            modalRef.afterClosed().subscribe({
                next: (yes) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    if (yes) {
                        this.schedulerService.save(scheduleModel).subscribe({
                            next: () => {
                                this.isSaving = false;
                                this.deactiveLoading();
                                this.dialogRef.close('ok');
                            },
                            error: (error) => {
                                this.isSaving = false;
                                this.deactiveLoading();
                                this.generalService.snackbarMessage(error.message, 'Close');
                            },
                        });
                    }
                },
            });
        } else {
            const modalRef = this.matDialog.open(DialogSimpleComponent, {
                data: {
                    title: 'Confirm Edit Scheduler',
                    message: `Are you sure want to update ${this.schedulerFormGroup
                        .get('schedulerType')
                        .value.name.toLowerCase()} schedule "${cronstrue.toString(
                        this.schedulerFormGroup.get('cronExpression').value,
                        { verbose: true }
                    )}"?`,
                    buttonPositive: 'Yes',
                    buttonPositiveColor: 'warn',
                    buttonNegative: 'No',
                },
                panelClass: 'mat-dialog-sm',
                id: 'edit-scheduler',
            });

            modalRef.afterClosed().subscribe({
                next: (yes) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    if (yes) {
                        this.schedulerService.update(scheduleModel).subscribe({
                            next: () => {
                                this.isSaving = false;
                                this.deactiveLoading();
                                this.dialogRef.close('ok');
                            },
                            error: (error) => {
                                this.isSaving = false;
                                this.deactiveLoading();
                                this.generalService.snackbarMessage(error.message, 'Close');
                            },
                        });
                    }
                },
            });
        }
    }

}
