import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { GeneralService } from 'src/app/services/general.service';
import { ISchedulerTypeOptions, SchedulerService } from 'src/app/services/scheduler.service';
import { SchedulerNewOrEditComponent } from '../scheduler-new-or-edit/scheduler-new-or-edit.component';
import { SchedulerModel } from 'src/app/model/scheduler.model';
import cronstrue from 'cronstrue';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-aggregation-schedule-listing',
    templateUrl: './scheduler-listing.component.html',
})
export class SchedulerListingComponent implements OnInit {
    readonly SCHEDULER_CREATE = ApplicationPermissionService.SCHEDULER_CREATE;
    readonly SCHEDULER_UPDATE = ApplicationPermissionService.SCHEDULER_UPDATE;
    readonly SCHEDULER_DELETE = ApplicationPermissionService.SCHEDULER_DELETE;
    readonly SCHEDULER_RUN_AGGREGATION = ApplicationPermissionService.SCHEDULER_RUN_AGGREGATION;
    readonly SCHEDULER_UNFREEZE_SUBMISSION = ApplicationPermissionService.SCHEDULER_UNFREEZE_SUBMISSION;
    readonly SCHEDULER_INITIAL_CONSOLIDATION = ApplicationPermissionService.SCHEDULER_INITIAL_CONSOLIDATION;
    readonly SCHEDULER_FINAL_CONSOLIDATION = ApplicationPermissionService.SCHEDULER_FINAL_CONSOLIDATION;
    readonly SCHEDULER_SHIFT_DATES = ApplicationPermissionService.SCHEDULER_SHIFT_DATES;
    readonly SCHEDULER_READ = ApplicationPermissionService.SCHEDULER_READ;

    schedulerDataSource = new MatTableDataSource<SchedulerModel>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['jobName', 'schedulerType', 'cronExpression', 'cronDescription', 'actions'];

    schedulerSearchValue: String = null;
    schedulerNumberOfRecords: number = 0;
    schedulerCurrentPage: number = 0;
    schedulerPageSize: number = 50;
    schedulerSortOrder = 'jobName,asc';
    loading: boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    schedulerTypeOptions: ISchedulerTypeOptions[] = SchedulerService.SCHEDULER_TYPE_OPTIONS

    constructor(
        public schedulerActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private schedulerService: SchedulerService,
        private generalService: GeneralService
    ) {}

    ngOnInit(): void {
        this.schedulerActiveLoading();
        if (this.schedulerActivatedRoute.snapshot.queryParams['page']) {
            this.schedulerSortOrder = this.schedulerActivatedRoute.snapshot.queryParams['sort']
                ? this.schedulerActivatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.schedulerCurrentPage = this.schedulerActivatedRoute.snapshot.queryParams['page']
                ? this.schedulerActivatedRoute.snapshot.queryParams['page']
                : undefined;
            this.schedulerPageSize = this.schedulerActivatedRoute.snapshot.queryParams['pageSize']
                ? this.schedulerActivatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    dataToSchedulerType(schedulerModel: SchedulerModel) {
        const data = this.schedulerTypeOptions.find((scheduler: ISchedulerTypeOptions) => scheduler.destination === schedulerModel.data.destination)
        return data.name
    }

    getCronDescription(cronsExpression: string) {
        return cronstrue.toString(cronsExpression, { verbose: true });
    }

    openSchedulerDialogCreateOrEdit(scheduler?: SchedulerModel) {
        this.passDataToDialogCreateOrEdit(scheduler);
    }

    passDataToDialogCreateOrEdit(event: SchedulerModel) {
        const modalRef = this.matDialog.open(SchedulerNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.schedulerCurrentPage, this.schedulerSearchValue);
            },
        });
    }

    search(currentPage: number, schedulerSearchValue: String) {
        this.schedulerSearchValue = schedulerSearchValue;
        this.schedulerService.getAllSchedulers(this.schedulerCurrentPage, this.schedulerPageSize, this.schedulerSortOrder, this.schedulerSearchValue)
        .subscribe({
            next: ({ body }) => {
                this.schedulerDataSource.data = body.content;
                this.schedulerNumberOfRecords = body.totalElements;
                this.schedulerDeactiveLoading();

                if (this.schedulerCurrentPage === 0) {
                    this.paginator.firstPage();
                } else {
                    this.paginator.pageIndex = this.schedulerCurrentPage;
                }
            },
            error: (error) => {
                this.generalService.snackbarMessage(error.debugMessage, 'Close');
                this.schedulerDeactiveLoading();
            },
        });
    }

    refreshForm() {
        this.search(0, null);
    }

    sortPage(event): void {
        this.schedulerSortOrder = `${event.active},${event.direction}`;
        this.search(this.schedulerCurrentPage, this.schedulerSearchValue);
    }

    navigateToPage(event): void {
        this.schedulerPageSize = event.pageSize;
        this.schedulerCurrentPage = event.pageIndex;
        this.search(this.schedulerCurrentPage, this.schedulerSearchValue);
    }

    openSchedulerDialogDelete(schedulerModel: SchedulerModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: `Confirm Delete Schedule Configuration?`,
                message: `Are you sure want to delete ${schedulerModel.jobName}?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.delete(schedulerModel.jobName, schedulerModel.jobGroup).subscribe({
                        next: () => {
                            this.search(this.schedulerCurrentPage, this.schedulerSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }

    schedulerActiveLoading() {
        this.loading = true;
    }

    schedulerDeactiveLoading() {
        this.loading = false;
    }

    onRunCBSAggregation() {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Run Aggregation',
                message: `Confirm to run aggregation now? This will disable all corporate report submissions immediately.`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'Cancel',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.runAggregation().subscribe({
                        next: () => {
                            this.generalService.snackbarMessage('Run Aggregation triggered', 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    })
                }
            },
        });
    }

    onUnfreezeCBSSubmission() {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Unfreeze Submission',
                message: `Confirm to unfreeze corporate report submissions?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'Cancel',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.unfreezeSubmission().subscribe({
                        next: () => {
                            this.generalService.snackbarMessage('Unfreeze Submission Successful', 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    })
                }
            },
        });
    }

    onRunRIOInitialConsolidation() {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Run Initial Consolidation',
                message: `Confirm to run Initial Consolidation now?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'Cancel',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.runInitialConsolidation().subscribe({
                        next: () => {
                            this.generalService.snackbarMessage('Run Initial Consolidation triggered', 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    })
                }
            },
        });
    }

    onRunRIOFinalConsolidation() {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Run Final Consolidation',
                message: `Confirm to run final consolidation now?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'Cancel',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.runFinalConsolidation().subscribe({
                        next: () => {
                            this.generalService.snackbarMessage('Run Final Consolidation triggered', 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    })
                }
            },
        });
    }

    onRunRIOShiftDates() {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Run Shift Dates',
                message: `Confirm to run shift dates now? This will shift the RIO year month.`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'Cancel',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.schedulerService.runShiftDates().subscribe({
                        next: () => {
                            this.generalService.snackbarMessage('Run Shift Dates triggered', 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    })
                }
            },
        });
    }
}
