<div class="mx-10 my-6" *appScreenPermission="[SCHEDULER_READ]">
  <mx-title-bar title="Scheduler Configuration" buttonIcon="add" buttonLabel="Add Schedule"
                (onButtonClick)="openSchedulerDialogCreateOrEdit()" [buttonPermission]="[SCHEDULER_CREATE]"></mx-title-bar>
   <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
  <div class="my-2">
      <strong>{{ schedulerNumberOfRecords }}</strong> result<span *ngIf="schedulerNumberOfRecords > 1">s</span>
  </div>
  <mat-card-content class="mx-content-table">
      <div *ngIf="loading">
          <mx-data-loading></mx-data-loading>
      </div>
      <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="schedulerDataSource" *ngIf="schedulerDataSource.data"
             class="w-full" aria-label="table-segment">
          <ng-container matColumnDef="jobName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">
                  {{ element.jobName || '-' }}
              </td>
          </ng-container>

          <ng-container matColumnDef="schedulerType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduler Type</th>
            <td mat-cell *matCellDef="let element">
                {{ dataToSchedulerType(element) }}
            </td>
        </ng-container>

          <ng-container matColumnDef="cronExpression">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Cron Expression</th>
              <td mat-cell *matCellDef="let element">
                  {{ element.cronExpression || '-' }}
              </td>
          </ng-container>

          <ng-container matColumnDef="cronDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Cron Description</th>
              <td mat-cell *matCellDef="let element">
                  {{getCronDescription(element.cronExpression)}}
              </td>
          </ng-container>

          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
              <td mat-cell *matCellDef="let element">
                  <mat-icon class="mr-4" (click)="openSchedulerDialogCreateOrEdit(element)" matTooltip="Edit"
                            class="cursor-pointer" *appScreenPermission="[SCHEDULER_UPDATE]">edit</mat-icon>
                  <mat-icon class="mr-4" (click)="openSchedulerDialogDelete(element)" matTooltip="Delete"
                            class="cursor-pointer" *appScreenPermission="[SCHEDULER_DELETE]">delete</mat-icon>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="6">No data match your search "{{ schedulerSearchValue }}"</td>
          </tr>
      </table>
  </mat-card-content>
  <mat-paginator (page)="navigateToPage($event)" [length]="schedulerNumberOfRecords" [pageSize]="schedulerPageSize"
                 [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
  </mat-paginator>
    <div class="flex flex-col">
        <div class="flex flex-row justify-between mb-4">
            <div class="my-auto text-xl font-bold">Manual Options</div>
        </div>
        <div class="flex flex-col">
            <div class="mb-4">
                <div class="text-xl mb-2">Corporate Budget System (CBS)</div>
                <div class="flex flex-row">
                    <button mat-raised-button *appScreenPermission="[SCHEDULER_RUN_AGGREGATION]" color="accent" (click)="onRunCBSAggregation()">
                        <span class="text-sm font-bold">Run Aggregation</span>
                    </button>
                    <div class="mx-2"></div>
                    <button mat-raised-button *appScreenPermission="[SCHEDULER_UNFREEZE_SUBMISSION]" color="accent"  (click)="onUnfreezeCBSSubmission()">
                        <span class="text-sm font-bold">Unfreeze Submission</span>
                    </button>
                </div>
            </div>
            <div>
                <div class="text-xl mb-2">Rolling Inflow Outflow (RIO) </div>
                <div class="flex flex-row">
                    <button mat-raised-button *appScreenPermission="[SCHEDULER_INITIAL_CONSOLIDATION]" color="accent"  (click)="onRunRIOInitialConsolidation()">
                        <span class="text-sm font-bold">Run Initial Consolidation</span>
                    </button>
                    <div class="mx-2"></div>
                    <button mat-raised-button *appScreenPermission="[SCHEDULER_FINAL_CONSOLIDATION]" color="accent"  (click)="onRunRIOFinalConsolidation()">
                        <span class="text-sm font-bold">Run Final Consolidation</span>
                    </button>
                    <div class="mx-2"></div>
                    <button mat-raised-button *appScreenPermission="[SCHEDULER_SHIFT_DATES]" color="accent"  (click)="onRunRIOShiftDates()">
                        <span class="text-sm font-bold">Run Shift Dates</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
